@tailwind components;
@tailwind utilities;

@layer components {
  .all-\[unset\] {
    all: unset;
  }
}

:root {
  --button-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
}

.upload-rectangle {
  width: 100px;
  height: 100px;
  border: 1px dashed #0053d6;
  border-radius: 8px;
  margin: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-rectangle .ant-upload-wrapper {
  width: 100%;
  height: 100%;
}

.upload-rectangle .ant-upload {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-upload-list-text {
  display: none;
}

.ant-image-preview-root {
  display: none;
}
